.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); /* Largura fixa para cada card */
  gap: 10px; /* Espaçamento entre os cards */
  justify-items: start; /* Alinha os cards no início */
}

.dynamic-card {
  width: 350px; /* Define a largura fixa */
  padding: 16px;
  border: 5px solid black; /* Borda preta de 2 pixels */
  border-radius: 15px; /* Bordas arredondadas */
  text-align: center;
  background-color: transparent;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Sombra leve */
}

.status-bars {
  margin-top: 10px;
}

.status-bar {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  position: relative;
}

.status-label {
  font-size: 14px;
  margin-right: 10px;
  white-space: nowrap; /* Impede que o texto quebre */
}

.bar-container {
  flex: 1; /* Flexível para aumentar o espaço disponível para as barras */
  height: 15px;
  background: #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
  position: relative; /* Necessário para posicionamento relativo do número */
  padding-right: 40px; /* Cria espaço para o número final */
}

.bar {
  height: 100%;
  
  background-color: #008000; /* Exemplo de cor */
}

.pending-count {
  position: absolute;
  right: 0; /* Posiciona o número ao final do container */
  top: 50%;
  transform: translateY(-50%); /* Centraliza verticalmente o número */
  font-size: 15px;
  color: #333;
  white-space: nowrap; /* Impede quebra de linha */
  z-index: 10; /* Garante que o número fique visível */
}
